import './App.css';
import Body from "./Body";
import Privacy from "./Privacy";
import Terms from "./Terms";

import ScrollToTop from "./ScrollToTop";

import Layout from "./Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Body />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
