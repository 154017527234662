import useWindowSize from './hooks/useWindowSize';
import React, { useEffect, useRef, useState } from 'react';

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};


const onClickUrl = (url) => {
    return () => openInNewTab(url);
};

const Header = () => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 768;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);

    };

    const logoRef = useRef(null);
    let i = 0;

    function mask(a) {
        i++;
        if (i < a) {
            const style = `-webkit-mask:-webkit-gradient(radial, 17 17, ${i}, 17 17, ${i + 15}, from(rgb(255, 255, 255)), color-stop(0.5, rgba(255, 255, 255, 0.2)), to(rgb(255, 255, 255)))`;
            logoRef.current.style.cssText = style;

            setTimeout(function () {
                mask(a);
            }, 20 - i);
        } else {
            i = 0;
        }
    }

    useEffect(() => {
        const logoElement = logoRef.current;
        logoElement.addEventListener("mouseenter", () => mask(200));

        // Cleanup function
        return () => {
            logoElement.removeEventListener("mouseenter", () => mask(200));
        };
    }, []);

    return (
        <nav className="bg-white border-gray-200 bg-color text-white w-full">
            <div className="w-full flex items-center justify-between mx-auto p-4">
                <a href="/" className="flex items-center logo" ref={logoRef}>
                    <img src="https://assets.terrarium.community/Domain/logo_trans.png" className="h-16 mr-3" alt="Terrarium Logo" />
                    <span className="self-center text-2xl font-bold whitespace-nowrap text-white">Terrarium</span>
                </a>
                <div className="flex md:items-center">
                    {!isMobile && (
                        <a href="https://growth.terrarium.community/" className="mx-10 hover:underline">
                            GROWTH
                        </a>
                    )}
                    <div className="w-auto" id="navbar-default">
                        <button
                            className="button-79 p-4 md:px-5 md:py-4 bg-blue-700 w-auto rounded-lg  drop-shadow-buttons text-center"
                            onClick={onClickUrl(process.env.REACT_APP_VALIDATOR_LINK)}
                        >
                            {!isMobile ? ('STAKE WITH US') : ('STAKE')}
                        </button>
                    </div>

                </div>
                <button
                    type="button"
                    className="md:hidden inline-flex items-center justify-center p-2 w-10 h-10 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    onClick={handleMenuToggle}
                >
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 14">
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h15M1 7h15M1 13h15"
                        />
                    </svg>
                </button>
            </div>
            <div
                className={`${isMobile && isMenuOpen ? 'block' : 'hidden'
                    } w-full`}
                id="navbar-hamburger"
            >
                <ul className="flex flex-col font-medium mt-4 rounded-lg bg-gray-50">
                    <li>
                        <a href="https://growth.terrarium.community/" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded bg-blue-600" >
                            Growth
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Header;
