import openInNewTab from "./components/openInNewTab";
import React, { useRef, useEffect } from 'react';
import anime from 'animejs';


export default function Body() {
    const textWrapperRef = useRef(null);
    const otherElementRef = useRef(null);


    useEffect(() => {
        const textWrapper = textWrapperRef.current;
        const otherWrapper = otherElementRef.current;
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        otherWrapper.innerHTML = otherWrapper.textContent.replace(/\S/g, "<span class='text'>$&</span>");

        anime.timeline({ loop: true })
            .add({
                targets: '.letter',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 2250,
                delay: (el, i) => 300 * (i + 1),
            }).add({
                targets: '.ml3',
                opacity: 0,
                duration: 1000,
                easing: "easeOutExpo",
                delay: 1000,
            });

        anime.timeline({ loop: true })
            .add({
                targets: '.text',
                opacity: [0, 1],
                easing: "easeInOutQuad",
                duration: 1000,
                delay: (el, i) => 100 * (i + 1),
            }).add({
                targets: '.ml12',
                opacity: 0,
                duration: 1000,
                easing: "easeOutExpo",
                delay: 1000,
            });

    }, []);



    return (<div>
        <div className="relative">
            <img src="https://assets.terrarium.community/Home/banner.jpg" className="relative w-full drop-shadow-2xl" />
            <div class="absolute inset-y-0 right-0 flex flex-col font-bold items-center justify-center text-white  pr-[10vw] pt-[10vw] xl:pr-20">
                <div className="text-[8vw] xl:text-8xl ml3 text-stroke" ref={textWrapperRef}>TERRARIUM</div>
                <div className="text-[3vw] xl:text-3xl ml12 text-stroke-unset " ref={otherElementRef}>SUSTAINABILITY. SCALABILITY. INCLUSIVITY.</div>
            </div>
        </div>

        <div className="flex flex-col w-full items-center justify-between mx-auto bg-white content-center pt-16 pb-24 shadow-inner-terr  h-auto">
            <div className=" lg:w-3/4 bg-color-header rounded-lg p-6 text-white drop-shadow-buttons underline flex-row flex h-auto w-full mt-4 px-24 mx-auto justify-center items-center text-header">
                TERRARIUM FEATURES
            </div>
            <div className="lg:flex-row flex-col lg:h-72 h-auto  w-full xl:px-40 mt-16 flex justify-center mx-auto " >
                <div className="items-stretch sm:h-72 h-auto w-full content-center flex flex-row lg:w-1/3 lg:flex-col feature-icon justify-center">
                    <div className="flex flex-col h-full">
                        <a href={process.env.REACT_APP_VALIDATOR_LINK}>
                            <img src="/assets/validator_512px.png"
                                className=" h-36 w-auto object-contain drop-shadow-icons flex-row w-full" />
                        </a>
                        <div className="drop-shadow-icons flex-row text-roboto text-slate-800 mb-1">
                            <span className="pill-blue-bgcolor text-white text-lg font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                Decentralized Validator</span>

                        </div>
                        <div class="text-base bg-white rounded-lg w-4/6 px-2 py-4 drop-shadow-buttons text-center mx-auto align-middle flex flex-col  flex-grow items-center justify-center">
                            <div class="flex flex-col h-auto justify-center">
                                Self hosted node with back-up Uninterrupted Power Supply, ensuring consistent uptime.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="items-stretch sm:h-72 h-auto w-full content-center flex flex-row lg:w-1/3 lg:flex-col feature-icon justify-center">
                    <div className="flex flex-col h-full">
                        <a href="https://growth.terrarium.community/">
                            <img src="https://assets.terrarium.community/Home/growth_512_px.png"
                                className=" h-36 w-auto object-contain drop-shadow-icons flex-row w-full" />
                        </a>

                        <div className="drop-shadow-icons flex-row text-roboto text-slate-800 mb-1">
                            <span className="pill-blue-bgcolor text-white text-lg font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                <a href="https://growth.terrarium.community/">
                                    Growth</a></span>
                        </div>


                        <div class="text-base bg-white rounded-lg w-4/6 px-2 py-4 drop-shadow-buttons text-center mx-auto align-middle flex flex-col  flex-grow items-center justify-center">
                            <div class="flex flex-col h-auto justify-center">
                                <div>Terra Classic application and contributor directory. Presenting on-chain builders.</div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="w-full items-stretch h-72 content-center flex flex-col lg:w-1/3 justify-center">
                    <div className="flex flex-col h-full">
                        <div>
                            <img src="/assets/tbd.png"
                                className=" h-36 w-auto object-contain drop-shadow-icons flex-row w-full" />
                        </div>
                        <div className="drop-shadow-icons flex-row text-roboto text-slate-800 mb-1">

                            <span className="pill-blue-bgcolor text-white text-lg font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                Under Development</span>
                        </div>
                        <div class="text-base bg-white rounded-lg w-4/6 px-2 py-4 drop-shadow-buttons text-center mx-auto align-middle flex flex-col  flex-grow items-center justify-center">
                            <div class="flex flex-col h-10 justify-center">
                                <img class="object-contain h-5/6" src="https://assets.terrarium.community/Home/pikachu_loading.gif" />
                                <div>Coming soon.</div>
                            </div>

                        </div>
                    </div>
                </div>

                {/*<div className="items-stretch h-60 flex-grow w-full  flex-row content-center flex-row items-stretch lg:w-1/3" items-stretch="" h-60="" flex-grow="" w-full="" flex-row="" content-center="" lg:w-1=""><img src="/assets/analytics_512px.png" className=" h-3/4 w-auto object-contain drop-shadow-icons flex-row"><div className="flex-row text-roboto text-slate-800 justify-center">Data Analytics</div></div>*/}
            </div>

            <div className="w-full flex-col lg:flex-row h-auto  mt-4 lg:px-24 mx-auto justify-center lg:mt-8 items-center lg:flex lg:w-5/6 justify-center mx-auto">
                <div className="w-full items-stretch h-72 content-center flex flex-col lg:w-1/3">
                    <div className="flex flex-col h-full">
                        <div>
                            <img src="/assets/tbd.png"
                                className=" h-36 w-auto object-contain drop-shadow-icons flex-row w-full" />
                        </div>
                        <div className="drop-shadow-icons flex-row text-roboto text-slate-800 mb-1">

                            <span className="pill-blue-bgcolor text-white text-lg font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                Under Development</span>
                        </div>
                        <div class="text-base bg-white rounded-lg w-4/6 px-2 py-4 drop-shadow-buttons text-center mx-auto align-middle flex flex-col  flex-grow items-center justify-center">
                            <div class="flex flex-col h-10 justify-center">
                                <img class="object-contain h-5/6" src="https://assets.terrarium.community/Home/pikachu_loading.gif" />
                                <div>Coming soon.</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="w-full items-stretch h-72 content-center flex flex-col lg:w-1/3">
                    <div className="flex flex-col h-full">
                        <div>
                            <img src="/assets/tbd.png"
                                className=" h-36 w-auto object-contain drop-shadow-icons flex-row w-full" />
                        </div>
                        <div className="drop-shadow-icons flex-row text-roboto text-slate-800 mb-1">

                            <span className="pill-blue-bgcolor text-white text-lg font-medium mr-2 px-2.5 py-0.5 rounded-full">
                                Under Development</span>
                        </div>
                        <div class="text-base bg-white rounded-lg w-4/6 px-2 py-4 drop-shadow-buttons text-center mx-auto align-middle flex flex-col  flex-grow items-center justify-center">
                            <div class="flex flex-col h-10 justify-center">
                                <img class="object-contain h-5/6" src="https://assets.terrarium.community/Home/pikachu_loading.gif" />
                                <div>Coming soon.</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>




            <div className="block flex-row p-1 w-full h-auto align-middle lg:flex lg:px-16 lg:py-10 lg:mt-16">
                <div className="w-full h-auto flex flex-row lg:w-1/4 lg:flex-col justify-center p-8">
                    <img src="https://assets.terrarium.community/Domain/logo_trans.png" className="h-96 object-contain drop-shadow-icons " alt="Terrarium Logo" />
                </div>
                <div className=" text-roboto text-slate-800 align-middle h-auto flex-row w-full lg:flex-col lg:w-3/4 ">
                    <div className="bg-color-header rounded-lg p-6 text-white drop-shadow-buttons underline flex-row flex h-auto w-full mt-4 px-24 mx-auto justify-center items-center text-header">
                        ABOUT TERRARIUM</div>
                    <div className="bg-white rounded-lg px-20  py-8 drop-shadow-buttons mt-8 text-justify align-middle flex flex-col h-auto flex items-center justify-center">
                        Our team is composed of blockchain enthusiasts, skilled IT professionals, and individuals with diverse expertise. We view our collective efforts as cultivating a microcosm akin to a terrarium—an enclosed ecosystem provided with specific nutrients and fundamental structures to facilitate the thriving of life forms.
                        <br /> <br />
                        Dedicated to creating an environment that encourages growth, we aim to support every layer of each protocol we engage with. Whether enhancing network security through self-hosted validator nodes, equipping developers with essential tools for chain onboarding, or developing real-world utilities on layer 2, our continuous mission is to simplify interactions with the chain, ensuring they are user-friendly and actionable.<br /><br />

                        Grow with us.</div>
                </div>



            </div>

            <div className="relative w-full md:h-auto mt-16 h-64 ">
                <img src="https://assets.terrarium.community/Home/partners_banner.gif" className="relative w-full h-full object-cover " />
                <div className="flex flex-col absolute top-0 inset-x-0 justify-center text-white h-full ">
                    <div className="flex flex-col absolute top-0 inset-x-0 justify-center h-full ">
                        <div className=" absolute inset-x-0 top-0 p-4 underline text-white drop-shadow-buttons underline flex-row flex h-auto w-full mt-4 px-24 mx-auto justify-center items-center text-header">
                            PARTNERSHIPS</div>
                        <div className="h-full flex justify-center text-white items-center p-6">
                            <div className="flex flex-col h-full justify-center items-center cursor-pointer" onClick={openInNewTab('https://www.terratritium.com/')}>
                                <img src='https://cdn.discordapp.com/attachments/886324113445093477/1165415897532481567/Untitled_design_8.png?ex=6546c522&is=65345022&hm=8b4e93a6596d1f6fec79beb76c2d1af6f9911c6942a6508d8a65bfab99474830&' className="h-2/4 drop-shadow-icons" />
                                <span className="pill-blue-bgcolor text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full w-fit relative bottom-3 ">
                                    Terra Tritium</span>
                            </div>


                        </div>
                        <div className="absolute inset-x-0 bottom-0 justify-center text-white p-4">
                            TOGETHER WE THRIVE. TOGETHER WE GROW.
                        </div>

                    </div>
                </div>


            </div>

            <div className="block flex-row p-1 w-full h-auto align-middle lg:flex lg:px-16 lg:py-10 shadow-inner-terr">
                <div className=" text-roboto text-slate-800 align-middle h-auto flex-row w-full lg:flex-col lg:w-3/4 ">
                    <div className="bg-color-header rounded-lg p-6 text-white drop-shadow-buttons underline flex-row flex h-auto w-full mt-4 px-24 mx-auto justify-center items-center text-header">
                        COMMUNITY</div>

                    <div className="bg-white rounded-lg px-20  py-8 drop-shadow-buttons mt-8 text-justify align-middle flex flex-col h-auto flex items-center justify-center">
                        Community stands as a cornerstone in the success of any project, particularly within the cryptosphere. From the initial days of our team's inception, the Terrarium team has been unwavering in its commitment to fostering a well-organized, secure, and collaborative environment for users within the wider Cosmos ecosystem. We've established a virtual town center, providing a space for individuals to engage in discussions, stay informed about core protocol developments, and keep abreast of activities within supporting layer 2s.<br /><br />
                        Facilitating the dispersal of accurate and current information so the community as a whole can become and stay educated on fundamentals and how to properly execute on chain functions or interactions.  Building the needed bridges between individuals or teams to better propagate the growth and vitality of the chain and its ecosystem.
                        <br /><br />
                        Our focus extends to disseminating accurate and timely information, ensuring that the entire community is well-versed in the fundamentals and adept at executing on-chain functions or interactions. By constructing bridges between individuals and teams, we aim to catalyze the growth and vitality of each chain we support and its broader ecosystem.<br /><br />
                        Join the community in its perpetual pursuit to nurture growth and witness its evolution.
                        <div className="m-10" onClick={openInNewTab('https://discord.gg/terrariumlunc')}
                        ><button className="bg-blue-700 button-79 button-cc-padding drop-shadow-buttons" role="button">JOIN THE COMMUNITY</button></div>


                    </div>

                </div>
                <div className="w-full h-auto flex flex-row lg:w-1/4 lg:flex-col justify-center p-8">
                    <img src="https://assets.terrarium.community/Home/discord_512px.png" className="h-96 object-contain drop-shadow-icons " alt="Terrarium Logo" />
                </div>




            </div>
        </div >
        <div className="relative h-auto mt-16 ">
            <img src="/assets/cc_banner_animated_1.gif" className="relative w-full h-full" />
            <div className="flex absolute inset-y-0 left-0 h-full justify-center items-center">
                <img className="h-full relative inset-y-0 left-0" src="/assets/cc_logo.png" /></div>

        </div>


        <div className="shadow-inner-terr  lg:p-16">
            <div className="block  p-1 w-full h-auto align-middle lg:flex  ">
                <div className="w-full h-auto flex flex-row lg:w-1/4 lg:flex-col justify-center p-8 my-16">
                    <img src="https://assets.terrarium.community/Home/coffee_512px.png" className="h-96 object-contain drop-shadow-icons " alt="Terrarium Logo" />
                </div>
                <div className=" text-roboto text-slate-800 align-middle h-auto flex-row w-full lg:flex-col lg:w-3/4 ">
                    <div className="bg-color-header rounded-lg p-6 text-white drop-shadow-buttons underline flex-row flex h-auto w-full mt-4 px-24 mx-auto justify-center items-center text-header">
                        COFFEE CORNER</div>
                    <div className="bg-white rounded-lg px-20  py-8 drop-shadow-buttons mt-8 text-justify align-middle flex flex-col h-auto flex items-center justify-center">
                        Coffee Corner is the Terrarium podcast covering the current events in Terra Classic and its developing layer 2s with a focus towards informative and educational discussion. Come chat with us and watch our Twitter for upcoming Spaces.
                        <br />
                        <br />
                        Podcast episodes subject to guest scheduling and availability.
                        <br />
                        See all our episodes on Youtube.
                        <div className="flex flex-row h-auto w-full mt-6 px-24 mx-auto justify-center items-center">
                            <div className="mx-4" onClick={openInNewTab('https://twitter.com/Clan_Terrarium')}
                            ><button className="bg-blue-700 button-79 button-cc-padding drop-shadow-buttons" role="button">TWITTER</button></div>
                            <div className="mx-4" onClick={openInNewTab('https://www.youtube.com/@ck.coffee.corner')}
                            ><button className="bg-blue-700 button-79 button-cc-padding drop-shadow-buttons" role="button">YOUTUBE</button></div>
                        </div>
                        <div className="flex flex-row">
                            <img src="/assets/mic_512px.png" className="mt-6 h-24	drop-shadow-icons" alt="Terrarium Logo" />
                            <div>
                                <img src="https://assets.terrarium.community/Home/waveform_512px.gif" className="ml-2 mt-6 h-24	" alt="Terrarium Logo" />
                            </div>
                        </div>
                    </div>


                </div>
            </div>




        </div>


    </div >
    );
};
