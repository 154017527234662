import './App.css';
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import { Outlet, Link } from "react-router-dom";

function Layout() {
    return (
        <div className="App">
            <Header className="text-ubuntu" />
            <Outlet />
            <Footer className="text-ubuntu" />

        </div>
    );
}

export default Layout;
